const isChinese = !!location.hostname.match("polaxiong")

const DEVELOPMENT = process.env.NODE_ENV === "development"

const ApiEndpoint = DEVELOPMENT
  ? isChinese
    ? "https://api-test.polaxiong.com/v1"
    : "https://api-test.polarr.co/v1"
  : isChinese
  ? "https://api.polaxiong.com/v1"
  : "https://api.polarr.co/v1"

export default {
  supportedStyleVersion: 3,
  styleEndpoint: "https://" + (isChinese ? "s.polaxiong.com" : "s.polarr.co"),
  cdnEndpoint: isChinese ? "https://ppe-assets.polaxiong.com/" : "https://ppe-assets.polarr.co/",
  apiEndpoint: ApiEndpoint,
  staticRoot: process.env.PUBLIC_URL ? `${process.env.PUBLIC_URL}static` : "./static",
}
