import "./dialog.scss"

export default function ({ header, content, button }) {
  return `
    <div class="dialog-content">
      <h2>${header}</h2>
      ${typeof content === "function" ? content() : content}
      
      <div class="buttons">
        <a href="#" id="dismiss" class="button">${button}</a>
      </div>
    </div>
  `
}
