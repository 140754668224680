import { state } from "../v2/state"
import { getActivatedExperiments } from "./experiments"
import { getStyleId } from "./style"
import { getURLParams } from "./utils"

const DEVELOPMENT = process.env.NODE_ENV === "development"
const COLLECTOR_ENDPOINT = DEVELOPMENT ? "ec2-18-144-18-51.us-west-1.compute.amazonaws.com" : "collector.polarr.co"

window.snowplow("newTracker", "sp", COLLECTOR_ENDPOINT, {
  appId: "style_preview_web",
  eventMethod: "POST",
  forceSecureTracker: !DEVELOPMENT,
})

function trackEvent(schema, data = {}, contexts = []) {
  // default contexts
  contexts = contexts.concat([createExperimentsContext(), createStyleContext()])

  window.snowplow(
    "trackSelfDescribingEvent",
    {
      schema,
      data,
    },
    contexts
  )

  if (DEVELOPMENT) {
    console.log("Track Event:", schema, data, contexts)
  }
}

function createExperimentsContext() {
  const activatedExperiments = getActivatedExperiments()
  const experimentKeys = Object.keys(activatedExperiments)

  const activeExperiments = experimentKeys.map(key => {
    const experiment = activatedExperiments[key]

    return {
      experiment_key: key,
      variant_name: experiment.key,
      variant_payload: experiment.payload,
      source: experiment.source,
    }
  })

  return {
    schema: "iglu:com.polarr.style_preview.context/experiments/jsonschema/1-0-0",
    data: {
      active_experiments: activeExperiments,
    },
  }
}

function createStyleContext() {
  return {
    schema: "iglu:com.polarr.style_preview.context/style/jsonschema/1-0-0",
    data: {
      id: getStyleId(),
      name: state.style && state.style.name || "",
    },
  }
}

/// EVENTS ///

export function trackPageView() {
  const { cid } = getURLParams()

  trackEvent("iglu:com.polarr.style_preview.event/view_page/jsonschema/1-0-0", {
    campaign_id: cid || null,
    referrer: document.referrer,
  })
}

export function tapOpenPPE({ target_url }) {
  trackEvent("iglu:com.polarr.style_preview.event/tap_open_ppe/jsonschema/1-0-0", { target_url })
}

export function tapOpenPVE({ target_url }) {
  trackEvent("iglu:com.polarr.style_preview.event/tap_open_pve/jsonschema/1-0-0", { target_url })
}

export function tapDownloadApp({ target_type, target_url }) {
  trackEvent("iglu:com.polarr.style_preview.event/tap_download_app/jsonschema/1-0-0", { target_type, target_url })
}

export function tapAuthorName() {
  trackEvent("iglu:com.polarr.style_preview.event/tap_author_name/jsonschema/1-0-0", {})
}

export function tapAuthorLink({ handle_type, handle_url }) {
  trackEvent("iglu:com.polarr.style_preview.event/tap_author_link/jsonschema/1-0-0", { handle_type, handle_url })
}

export function tapOpenPhoto() {
  trackEvent("iglu:com.polarr.style_preview.event/open_photo/jsonschema/1-0-0", {})
}

export function tapExportPhoto() {
  trackEvent("iglu:com.polarr.style_preview.event/export_photo/jsonschema/1-0-0", {})
}
