import "./main.scss"
import config from "../../shared/config"
export default function ({ i18n, pveLink, ppeLink, }) {
  return `
    <div id="logo-header">
      <img src="${config.staticRoot}/logo.png" width="85" height="36" alt="Polarr">
    </div>
    <div class="container">
      <section>
        <div id="preview">
          <div class="overlay">
            <div class="spinner"></div>
          </div>
        </div>
      </section>
      <header>
        <div id="style-description">
          <h1 id="style-name"></h1>
          <a id="style-author" rel="author" href="#"></a>
          <div class="flex-spacer"></div>
          <div id="style-count"></div>
        </div>
        <div id="qr-code">
          <canvas width="1" height="1"></canvas>
        </div>
      </header>
      <div class="buttons">
        <a href="${pveLink}" id="open-pve" class="button primary" target="_blank" rel="noopener">
          <img src="${config.staticRoot}/icons/video.svg" alt="24FPS">
          ${i18n.use_on_video}
        </a>
        <a href="${ppeLink}" id="open-ppe" class="button primary" target="_blank" rel="noopener">
          <img src="${config.staticRoot}/icons/photo.svg" alt="Polarr">
          ${i18n.use_on_photo}
        </a>
      </div>
    </div>
  `
}
