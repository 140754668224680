import config from "./config"
import { set } from "./temp-storage"
import { bytesToUtf8, get, getURLParams, XOR } from "./utils"

export function getStyleId() {
  const params = getURLParams()
  const { id, v6id, filterId, legacyFilterId } = params

  if (id || v6id || filterId || legacyFilterId) {
    return id || v6id || filterId || legacyFilterId;
  }

  const urlPath = window.location.pathname.split("/")
  if (urlPath.length && urlPath[1]) {
    return urlPath[1]
  }

  return null;
}

export function getStyleUrl() {
  const styleId = getStyleId()
  return `${config.styleEndpoint}/${styleId}`
}

export async function fetchStyle(styleId) {
  const url = `${config.apiEndpoint}/ppe/filters/${styleId}/preview`
  return get(url)
}

export async function fetchStyleContent(style) {
  if (style.version >= 3) {
    const rawContent = await get(style.contentUrl, "arraybuffer")
    return decryptStyle(new Uint8Array(rawContent))
  } else {
    return await get(style.contentUrl, "json")
  }
}

async function downloadOverlay(overlay) {
  const { src, remoteSrc } = overlay

  if (!src) {
    return
  }

  const baseUrl = config.cdnEndpoint
  const url = remoteSrc ? remoteSrc : `${baseUrl}${src}`

  const blob = await get(url, "blob")
  const blobUrl = URL.createObjectURL(blob)

  set(src, blobUrl)
}

export async function downloadOverlaysFromState(state) {
  let layers = []

  const pushLayers = localState => {
    if (localState.layers) {
      layers = layers.concat(localState.layers.filter(layer => layer.type === "file"))
    }
  }

  // Global layers
  pushLayers(state)

  // Selection layers
  if (state.local_adjustments) {
    state.local_adjustments.forEach(pushLayers)
  }

  for (const layer of layers) {
    await downloadOverlay(layer)
  }
}

/**
 *
 * @param {ArrayBuffer} encryptedContent
 * @returns {any}
 */
export function decryptStyle(encryptedContent) {
  // extract key
  const key = encryptedContent.subarray(0, 32)

  // extract content
  const content = encryptedContent.subarray(32)

  // decrypt content
  const decryptedContent = XOR(content, key)

  // convert to string
  const json = bytesToUtf8(decryptedContent)

  return JSON.parse(json)
}

export function decodeStyleName(style) {
  try {
    return decodeURIComponent(style.name)
  } catch (e) {
    return style.name
  }
}
