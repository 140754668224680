const en = {
  page_title: "Polarr Style Preview",
  page_description: "Preview Polarr styles",
  error_webgl_not_supported:
    "Sorry, but your browser doesn't support rendering Styles. Please download Polarr to try out this Style.",
  error_loading_photo: "Sorry, that photo could not be loaded.",
  error_saving_photo: "Sorry, that photo could not be saved.",
  error_rendering_style: "Sorry, this Style could not be previewed.",
  error_loading_style: "Sorry, this Style could not be loaded.",
  imports: "imports",
  link: "Link",
  no_link_set: "no link set",
  download_polarr: "Download Polarr",
  open_photo: "Open Photo",
  save_photo: "Save Photo",
  dismiss: "Dismiss",
  scan_in_polarr: "Scan in Polarr to get this style",
  download_app: "Download App",
  polarr_support: "Polarr Support",
  style_made_by: "Style made by",
  use_on_video: "Use on video",
  use_on_photo: "Use on photo",
}

const zh = {
  page_title: "泼辣滤镜预览",
  page_description: "预览泼辣滤镜",
  error_webgl_not_supported: "抱歉，您的浏览器无法渲染此滤镜。请下载 Polarr 以尝试该滤镜。",
  error_loading_photo: "抱歉，该照片无法加载。",
  error_saving_photo: "抱歉，该照片无法保存。",
  error_rendering_style: "抱歉，该滤镜无法预览。",
  error_loading_style: "抱歉，该滤镜无法加载。",
  imports: "导入",
  link: "链接",
  no_link_set: "未设置链接",
  download_polarr: "下载 Polarr",
  open_photo: "打开照片",
  save_photo: "保存照片",
  dismiss: "取消",
  scan_in_polarr: "使用 Polarr 扫码即可获得此滤镜",
  download_app: "下载 App",
  polarr_support: "Polarr 用户支持",
  style_made_by: "滤镜由",
  use_on_video: "在视频上使用",
  use_on_photo: "在图片上使用",
}

// const isChinese = new RegExp(/zh/, "gi").test(navigator.language);

// Switch language based on hostname instead.
const isChinese = !!location.hostname.match("polaxiong")
const i18n = isChinese ? zh : en

document.documentElement.lang = isChinese ? "zh" : "en"
document.querySelector('meta[name="description"]').setAttribute("content", i18n.page_description)

export { isChinese }
export default i18n
