export function clamp(n) {
  return n < 0 ? 0 : n > 1 ? 1 : n
}

export function get(url, responseType = "json") {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest()

    req.onload = function () {
      if (req.status > 299) {
        reject(req.response)
      } else {
        resolve(req.response)
      }
    }

    req.onerror = reject
    req.onabort = reject

    req.open("GET", url)
    req.responseType = responseType
    req.send()
  })
}

export function getURLParams() {
  const params = {}
  const urlSearchParams = location.search ? location.search.split("?")[1].split("&") : []

  urlSearchParams.forEach(function (param) {
    const pair = decodeURIComponent(param).split("=")
    params[pair[0]] = pair[1]
  })

  return params
}

/**
 * Accepts string or bytes and return bytes.
 * Encrypted bytes may not always be encodable as utf-8 using TextDecoder!
 * @param string
 * @param key
 */
export function XOR(string, key) {
  const stringBytes = typeof string === "string" ? utf8ToBytes(string) : string
  const keyBytes = typeof key === "string" ? utf8ToBytes(key) : key

  for (let i = 0; i < stringBytes.length; i++) {
    stringBytes[i] = stringBytes[i] ^ keyBytes[i % keyBytes.length]
  }

  return stringBytes
}

/**
 * @param {string} string
 * @returns {Uint8Array}
 */
export function utf8ToBytes(string) {
  const encoder = new TextEncoder()
  return encoder.encode(string)
}

/**
 * @param {Uint8Array} bytes
 * @returns {string}
 */
export function bytesToUtf8(bytes) {
  const decoder = new TextDecoder()
  return decoder.decode(bytes)
}

/**
 * Formats number according to locale.
 * @param {number} number
 * @returns {string|number}
 */
export function formatNumber(number) {
  if (typeof Intl !== "undefined" && typeof Intl.NumberFormat !== "undefined") {
    return new Intl.NumberFormat().format(number)
  } else {
    return number
  }
}

/**
 * jQuery extra extra extra lite
 * @param selector
 * @returns {any}
 */
export const $ = selector => document.querySelector(selector)

export class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolve = resolve
    })
  }
}

export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.crossOrigin = "anonymous"
    image.src = src
    image.onload = () => resolve(image)
    image.onerror = reject
  })
}

export const getTargetHref = e => (e.target && e.target.href) || (e.currentTarget && e.currentTarget.href)

/**
 * Manually generate Firebase Dynamic link.
 * See: https://firebase.google.com/docs/dynamic-links/create-manually
 * @param link
 * @param prefix
 */
export function createDynamicLink(link, prefix) {
  switch (prefix) {
    case "ppe":
      return `https://ppe.polarr.co/?link=${link}&apn=photo.editor.polarr&isi=988173374&ibi=co.polarr.ppe`

    case "pve":
      return `https://view.24fps.polarr.co/?link=${link}&isi=1341136787&ibi=co.polarr.pve`
  }
}

