const cache = {}

export async function set(key, value) {
  cache[key] = value
}

export async function get(key) {
  return cache[key]
}

export const getUrl = get
