import "./socialLinks.scss"
import prependHttp from "prepend-http"
import config from '../../shared/config'

const prepend = str => prependHttp(str || "")

export default function ({
  snapchatHandle,
  instagramHandle,
  facebookHandle,
  weiboHandle,
  tiktokHandle,
  twitterHandle,
}) {
  return `
   <div class="social-links">
     <a aria-label="instagram" ${!instagramHandle ? 'aria-disabled="true"' : ""} target="_blank" href="${prepend(
    instagramHandle
  )}"><img src="${config.staticRoot}/icons/instagram.svg" alt="Instagram"></a>
     <a aria-label="facebook" ${!facebookHandle ? 'aria-disabled="true"' : ""} target="_blank" href="${prepend(
    facebookHandle
  )}"><img src="${config.staticRoot}/icons/facebook.svg" alt="Facebook"></a>
     <a aria-label="snapchat" ${!snapchatHandle ? 'aria-disabled="true"' : ""} target="_blank" href="${prepend(
    snapchatHandle
  )}"><img src="${config.staticRoot}/icons/snapchat.svg" alt="Snapchat"></a>
     <a aria-label="tiktok" ${!tiktokHandle ? 'aria-disabled="true"' : ""} target="_blank" href="${prepend(
    tiktokHandle
  )}"><img src="${config.staticRoot}/icons/tiktok.svg" alt="TikTok"></a>
     <a aria-label="weibo" ${!weiboHandle ? 'aria-disabled="true"' : ""} target="_blank" href="${prepend(
    weiboHandle
  )}"><img src="${config.staticRoot}/icons/weibo.svg" alt="Weibo"></a>
     <a aria-label="twitter" ${!twitterHandle ? 'aria-disabled="true"' : ""} target="_blank" href="${prepend(
    twitterHandle
  )}"><img src="${config.staticRoot}/icons/twitter.svg" alt="Twitter"></a>
   </div>
 `
}
