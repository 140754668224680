import "normalize.css"
import "regenerator-runtime/runtime"
import config from "../shared/config"
import i18n from "../shared/i18n"
import { downloadOverlaysFromState, fetchStyle, fetchStyleContent, getStyleId, getStyleUrl } from "../shared/style"
import { tapAuthorName, tapOpenPPE, tapOpenPVE } from "../shared/tracker"
import { $, createDynamicLink, Deferred, getTargetHref } from "../shared/utils"
import "./css/common.scss"
import { state } from "./state"
import mainPage from "./templates/main"
import { renderBeforeAfterViewer, renderQRCode, renderStyleInfo, showDialog, showError } from "./ui"

const STYLE_ID = getStyleId()
const STYLE_URL = getStyleUrl()

const styleLoaded = new Deferred()
const styleContentLoaded = new Deferred()

const pveLink = createDynamicLink(`https://24fps.polarr.co/style/${STYLE_ID}`, "pve")
const ppeLink = createDynamicLink(`https://www.polarr.com/style/${STYLE_ID}`, "ppe")

// Render page
$("#main").innerHTML = mainPage({ i18n, pveLink, ppeLink })

// Tracking
$("#open-ppe").addEventListener("click", e => tapOpenPPE({ target_url: getTargetHref(e) }))
$("#open-pve").addEventListener("click", e => tapOpenPVE({ target_url: getTargetHref(e) }))
$("#style-author").addEventListener("click", () => tapAuthorName())

async function renderStyle() {
  await styleLoaded.promise

  const { previewImageUrl, previewAfterImageUrl } = state.style

  // If style already has before/after images, render those instead
  if (previewImageUrl && previewAfterImageUrl) {
    renderBeforeAfterViewer(previewImageUrl, previewAfterImageUrl)
    return
  }

  // Start deferred loading of style content
  loadStyleContent()

  const renderer = await import("./renderer")

  // Wait for preview image to load
  try {
    const previewImage = state.style.previewImageUrl || `${config.staticRoot}/sample.jpg`
    await renderer.importPhoto(previewImage)
  } catch (e) {
    showError(i18n.error_loading_photo, e)
    return
  }

  await styleContentLoaded.promise

  await renderStylePreview(renderer)
}

async function renderStylePreview(renderer) {
  try {
    const [before, after] = await renderer.renderBeforeAfter()
    renderBeforeAfterViewer(before, after)
  } catch (e) {
    showError(i18n.error_rendering_style, e)
  }
}

async function loadStyle() {
  // The style ID is grabbed from the page url so it's possible this would not be defined
  if (!STYLE_ID) {
    const error = new Error("Missing style ID")
    showError(i18n.error_loading_style, error)
    return
  }

  // We can render the QR code before the style is loaded
  renderQRCode(STYLE_URL)

  try {
    state.style = window.styleMetadata ? window.styleMetadata : await fetchStyle(STYLE_ID)
    styleLoaded.resolve()
  } catch (e) {
    showError(i18n.error_loading_style, e)
    styleLoaded.reject()
    styleContentLoaded.reject()
    return
  }

  renderStyleInfo()
  renderStyle()
}

async function loadStyleContent() {
  try {
    state.style.content = await fetchStyleContent(state.style)
    await downloadOverlaysFromState(state.style.content.state)
  } catch (e) {
    showError(i18n.error_loading_style, e)
    styleContentLoaded.reject()
    return
  }

  styleContentLoaded.resolve()
}

loadStyle()
