import i18n from "../shared/i18n"
import { createQRCode } from "../shared/qr"
import { decodeStyleName } from "../shared/style"
import { tapAuthorLink } from "../shared/tracker"
import { $, clamp, formatNumber, getTargetHref } from "../shared/utils"
import { state } from "./state"
import dialog from "./templates/dialog"
import preview from "./templates/preview"
import socialLinks from "./templates/socialLinks"

export function renderStyleInfo() {
  const { style } = state

  $("#style-name").innerText = decodeStyleName(style)
  $("#style-count").innerText =
    style.scanCount && style.scanCount >= 10000 ? `${formatNumber(style.scanCount)} ${i18n.imports}` : ""
  $("#style-author").innerText = style.author && style.author.username ? `@${style.author.username}` : ""

  $("#style-author").onclick = e => {
    e.preventDefault()
    renderAuthorDialog()
  }

  document.title = `${decodeStyleName(style)} - ${i18n.page_title}`
}

export async function renderQRCode(url) {
  try {
    const canvas = await createQRCode(url)

    $("#qr-code").innerHTML = ""
    $("#qr-code").appendChild(canvas)
  } catch (e) {}
}

export function renderAuthorDialog() {
  const { author } = state.style

  showDialog({
    header: author.username,
    content: socialLinks(author),
  })

  $(".social-links").addEventListener("click", e => {
    const label = e.target.ariaLabel

    if (label) {
      tapAuthorLink({ handle_url: getTargetHref(e), handle_type: label })
    }
  })
}

function showDialog(options) {
  const { header, content, button = i18n.dismiss } = options

  const dialogContent = dialog({ header, content, button })

  const dialogEl = document.createElement("div")

  document.body.appendChild(dialogEl)
  document.body.style.overflow = "hidden"

  dialogEl.className = "dialog"
  dialogEl.innerHTML = dialogContent
  dialogEl.offsetHeight // force redraw
  dialogEl.classList.add("active")

  dialogEl.onclick = e => {
    if (e.target === dialogEl) {
      // clicked on backdrop
      hideDialog(dialogEl)
    }
  }

  dialogEl.querySelector("#dismiss").onclick = e => {
    e.preventDefault()
    hideDialog(dialogEl)
  }
}

function hideDialog(element) {
  element.classList.remove("active")
  setTimeout(() => {
    if (element.parentElement !== null) {
      element.parentElement.removeChild(element)
      document.body.style.overflow = ""
    }
  }, 300)
}

/**
 * Renders error inside preview area
 * @param {string} message
 */
export function showError(message, error) {
  $("#preview").innerHTML = `
    <div class="overlay">
      ${message}
    </div>
  `
  if (window.Sentry) {
    Sentry.captureException(new Error(message), error)
  }

  // console.error(error)
}

/**
 * Renders before/after viewer
 * @param {Blob | string} before
 * @param {Blob | string} after
 */
export function renderBeforeAfterViewer(before, after) {
  const beforeSrc = typeof before === "string" ? before : URL.createObjectURL(before)
  const afterSrc = typeof after === "string" ? after : URL.createObjectURL(after)

  const previewEl = $("#preview")

  previewEl.innerHTML = preview({ beforeSrc, afterSrc })

  const previewAfterEl = $("#split-preview-b")

  const showBefore = (e) => {
    e.preventDefault()
    previewAfterEl.style.visibility = "hidden"
  }

  const showAfter = () => {
    previewAfterEl.style.visibility = "initial"
  }

  previewEl.addEventListener("touchstart", showBefore)
  previewEl.addEventListener("mousedown", showBefore)

  previewEl.addEventListener("touchend", showAfter)
  previewEl.addEventListener("touchcancel", showAfter)
  previewEl.addEventListener("mouseup", showAfter)
}
