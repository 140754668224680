import * as QRCode from "qrcode"

export function createQRCode(url) {
  return new Promise((resolve, reject) => {
    QRCode.toCanvas(
      url,
      {
        scale: 1,
        margin: 1,
        color: {
          dark: "#000000ff",
          light: "#ffffff00",
        },
      },
      (err, canvas) => {
        if (err) {
          reject(err)
        } else {
          resolve(canvas)
        }
      }
    )
  })
}
