import "./preview.scss"
import config from '../../shared/config'

export default function ({ beforeSrc, afterSrc }) {
  return `
    <div id="split-preview">
      <div class="split" id="split-preview-a">
        <img src="${beforeSrc}" alt="before" draggable="false" width="512" height="512">
      </div>
      <div class="split" id="split-preview-b">
        <img src="${afterSrc}" alt="after" draggable="false" width="512" height="512">       
      </div>
      <img style="position: absolute; right: 8px; bottom: 8px;" src="${config.staticRoot}/icons/compare.svg" width="24" height="24" alt="Show before">
    </div>
  `
}
