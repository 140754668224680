import { getURLParams } from "./utils"

const config = {
  page: {
    variants: {
      control: {
        payload: "v1",
        weight: 0.5,
      },
      variation: {
        payload: "v2",
        weight: 0.5,
      },
    },
  },
}

let activatedExperiments = getItem("experiments") || {}

/**
 * Activate an experiment. Returns the payload of the experiment variant.
 * @param experimentKey
 * @returns {string|SourceMapPayload|any}
 */
export function activateExperiment(experimentKey) {
  const urlParams = getURLParams()

  if (urlParams[experimentKey] != null) {
    return urlParams[experimentKey]
  }

  if (!activatedExperiments[experimentKey]) {
    activatedExperiments[experimentKey] = makeExperimentVariantAssignment(experimentKey)
    setItem("experiments", activatedExperiments)
  }

  return activatedExperiments[experimentKey].payload
}

export const getActivatedExperiments = () => activatedExperiments

/**
 * Read one experiment configuration item
 */
export const getExperiment = experiment => config[experiment]

/**
 * Get array of all experiment keys
 */
export const getExperimentKeys = () => Object.keys(config)

/**
 * Get array of all variant keys for a provided experiment
 */
export const getExperimentVariantKeys = experiment => Object.keys(config[experiment].variants)

/**
 * Get a variant configuration for a provided variant key
 */
export const getExperimentVariantConfig = (experimentKey, variantKey) => {
  const experiment = getExperiment(experimentKey)
  // @ts-ignore
  const variant = experiment.variants[variantKey]
  return variant
}

/**
 * Get the weight of an individual experiment variant, or fall back to an even distribution
 */
const getExperimentVariantWeight = (experimentKey, variantKey) => {
  const variantConfig = getExperimentVariantConfig(experimentKey, variantKey)

  return typeof variantConfig.weight === "number"
    ? variantConfig.weight
    : 1 / getExperimentVariantKeys(experimentKey).length
}

/**
 * Decide which variant of an experiment a user should be presented with
 * according to the current experiment configuration
 */
export const makeExperimentVariantAssignment = experimentKey => {
  const determinant = Math.random()

  let accWeights = 0

  for (const variantKey of getExperimentVariantKeys(experimentKey)) {
    accWeights += getExperimentVariantWeight(experimentKey, variantKey)

    if (determinant <= accWeights) {
      return {
        key: variantKey,
        source: "client",
        payload: getExperimentVariantConfig(experimentKey, variantKey).payload,
      }
    }
  }

  throw new Error("Could not make variant assignment")
}

function getItem(key) {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (e) {
    return null
  }
}

function setItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}
